
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";

.faqs-container {
    display: flex;
    flex-direction: column;
    @include handleAllBreakpoints('gap', 'spaceBetweenQuestions', '20px');
  
    .title {
      @include handleAllBreakpoints(
        'color',
        'titleFontColor',
        'var(--global-sections-typography-title-color)'
      );
      @include handleAllBreakpoints(
        'font-size',
        'titleFontSize',
        'var(--global-sections-typography-title-size)'
      );
      @include handleAllBreakpoints(
        'font-weight',
        'titleFontWeight',
        'var(--global-sections-typography-title-weight)'
      );
      @include handleAllBreakpoints('margin', 'titleMargin', '0');
    }
  
    .questions-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
  
      i {
        @include handleAllBreakpoints('color', 'questionFontColor', '#000');
      }
      @include handleAllBreakpoints(
        'background',
        'questionBackgroundColor',
        '#fafafa'
      );
      @include handleAllBreakpoints('border', 'questionBorder', 'none');
      @include handleAllBreakpoints(
        'border-radius',
        'questionBorderRadius',
        '10px'
      );
      @include handleAllBreakpoints('box-shadow', 'questionBoxShadow', 'none');
      @include handleAllBreakpoints('color', 'questionFontColor', '#000');
      @include handleAllBreakpoints('font-size', 'questionFontSize', '25px');
      @include handleAllBreakpoints(
        'font-weight',
        'questionFontWeight',
        'var(--global-sections-typography-title-weight)'
      );
      @include handleAllBreakpoints('padding', 'questionPadding', '10px');
    }
  
    .answers-container {
      margin: 10px 10px 0 10px;
      @include handleAllBreakpoints('background', 'answerBackgroundColor');
      @include handleAllBreakpoints('border', 'answerBorder', 'none');
      @include handleAllBreakpoints('border-radius', 'answerBorderRadius', '5px');
      @include handleAllBreakpoints(
        'box-shadow',
        'answerBoxShadow',
        'rgba(0, 0, 0, 0.04) 0px 3px 5px'
      );
      @include handleAllBreakpoints(
        'color',
        'answerFontColor',
        'var(--global-sections-typography-description-color)'
      );
      @include handleAllBreakpoints(
        'font-size',
        'answerFontSize',
        'var(--global-sections-typography-description-size)'
      );
      @include handleAllBreakpoints(
        'font-weight',
        'answerFontWeight',
        'var(--global-sections-typography-description-weight)'
      );
      @include handleAllBreakpoints('padding', 'answerPadding', '10px');
      @include handleAllBreakpoints('margin', 'answerMargin', '10px');
    }
  }
